import { useField } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import {
  formatCpf,
  formatCurrency,
  formatPhone,
  parseCpf,
  parseCurrency,
  parsePhone
} from '../../../util/formatters'

const valueTypes = {
  text: {
    format: _.identity,
    parse: _.identity
  },
  currency: {
    format: formatCurrency,
    parse: parseCurrency
  },
  phone: {
    format: formatPhone,
    parse: parsePhone
  },
  cpf: {
    format: formatCpf,
    parse: parseCpf
  }
}

export default function FormField ({ component, name, valueType, ...props }) {
  const Component = component ?? Form.Control
  const [field, meta, helpers] = useField(name)
  const { parse, format } = valueTypes[valueType] || valueTypes.text

  const handleChange = event => {
    const value = parse(event.target.value)
    helpers.setValue(value)
  }

  const value = format(meta.value)

  return (
    <>
      <Component
        {...field}
        isInvalid={meta.touched && meta.error}
        value={value ?? ''}
        onChange={handleChange}
        {...props}
      />
      <Form.Control.Feedback type='invalid'>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </>
  )
}
