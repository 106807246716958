import dayjs from 'dayjs'
import { Form as FormikForm, Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { paymentReportSchema } from '../../../util/schemas'
import MonthSelectField from '../../molecules/MonthSelect/MonthSelectField'

const monthStart = dayjs()
  .subtract(1, 'month')
  .startOf('month')

export default function PaymentReportForm ({
  initialValues,
  onSubmit,
  onCancel
}) {
  return (
    <Formik
      initialValues={
        initialValues || {
          minDate: monthStart.toDate(),
          maxDate: monthStart.date(monthStart.daysInMonth()).toDate(),
          peopleIds: null
        }
      }
      validationSchema={paymentReportSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <FormikForm>
          <Form.Group className='mb-3'>
            <Form.Label>Período</Form.Label>
            <MonthSelectField />
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Gerando…' : 'Gerar relatório'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Voltar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}
