import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import styles from './styles.module.css'

const monthStart = dayjs().startOf('month')
const currentYear = monthStart.year()
const currentMonth = monthStart.month()
const availableYears = _.times(10, n => currentYear - n)
const allMonths = _.times(12, n => n)
const availableMonths = allMonths.slice(0, currentMonth + 1)

export default function MonthSelect ({ value, onChange, ...props }) {
  const date = dayjs(value)

  const handleMonth = event => {
    onChange?.(date.month(+event.target.value).toDate())
  }

  const handleYear = event => {
    let newDate = date.year(+event.target.value)

    if (newDate.isAfter(monthStart, 'month')) {
      newDate = newDate.month(monthStart.month())
    }

    onChange?.(newDate.toDate())
  }

  const months = date.year() === monthStart.year() ? availableMonths : allMonths

  return (
    <div className={styles.wrapper}>
      <Form.Select value={date.month()} onChange={handleMonth} {...props}>
        {months.map(month => (
          <option key={month} value={month}>
            {date.month(month).format('MMMM')}
          </option>
        ))}
      </Form.Select>
      <Form.Select value={date.year()} onChange={handleYear} {...props}>
        {availableYears.map(year => (
          <option key={year}>{year}</option>
        ))}
      </Form.Select>
    </div>
  )
}

MonthSelect.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
}
