import * as yup from 'yup'

const passwordSchema = yup
  .string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    'A senha deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial (ex: !@#$%)'
  )

export const userUpdateSchema = yup
  .object()
  .shape({
    id: yup.string().uuid().nullable(),
    name: yup.string().required('Este campo é obrigatório'),
    email: yup
      .string()
      .email('Este e-mail é inválido')
      .required('Este campo é obrigatório'),
    password: passwordSchema.nullable(),
    isOwner: yup.bool().required()
  })
  .noUnknown()

export const userCreateSchema = userUpdateSchema.shape({
  password: passwordSchema.required('Este campo é obrigatório')
})

export const userRoleSchema = yup.object().shape({
  userId: yup.string().uuid().nullable(),
  placeId: yup
    .string()
    .uuid('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  role: yup.string().oneOf(['manager', 'supervisor', 'allocator']).required()
})

export const personSchema = yup
  .object()
  .shape({
    id: yup.string().uuid().nullable(),
    photoUrl: yup.string().url('A URL recebida é inválida').nullable(),
    name: yup.string().required('Este campo é obrigatório'),
    department: yup.string().required('Este campo é obrigatório'),
    graduation: yup.string().nullable(),
    courses: yup.string().nullable(),
    fullName: yup.string().nullable(),
    email: yup.string().email('Este e-mail é inválido'),
    phone: yup.string().nullable(),
    rg: yup.string().nullable(),
    cpf: yup.string().nullable(),
    bankingDetails: yup.string().nullable()
  })
  .noUnknown()

export const placeSchema = yup
  .object()
  .shape({
    id: yup.string().uuid().nullable(),
    name: yup.string().required('Este campo é obrigatório'),
    headcount: yup
      .number()
      .integer('Digite um número inteiro')
      .min(1, 'O efetivo mínimo é de 1 (um)')
      .required('Este campo é obrigatório'),
    leaderPrice: yup
      .number()
      .integer()
      .min(1000, 'O valor mínimo é de R$ 10 (dez reais)')
      .required('Este campo é obrigatório'),
    personPrice: yup
      .number()
      .integer()
      .min(1000, 'O valor mínimo é de R$ 10 (dez reais)')
      .required('Este campo é obrigatório'),
    retailPrice: yup
      .number()
      .integer()
      .min(1000, 'O valor mínimo é de R$ 10 (dez reais)')
      .required('Este campo é obrigatório')
  })
  .noUnknown()

export const allocationQuerySchema = yup.object().shape({
  minDate: yup.date(),
  maxDate: yup.date(),
  placeId: yup.string().uuid(),
  date: yup.date(),
  personId: yup.string().uuid()
})

export const allocationSchema = yup.object().shape({
  placeId: yup.string().uuid().required(),
  date: yup.date().required(),
  personId: yup.string().uuid().required(),
  isLeader: yup.boolean(),
  isBench: yup.boolean(),
  delay: yup.number().integer()
})

export const allocationCriteriaSchema = yup.object().shape({
  date: yup.date().required(),
  placeId: yup.string().uuid().required(),
  personId: yup.string().uuid().required()
})

export const paymentReportSchema = yup.object().shape({
  minDate: yup.date().required(),
  maxDate: yup.date().required(),
  peopleIds: yup.array().of(yup.string().uuid().required()).nullable()
})

export const invoiceReportSchema = yup.object().shape({
  minDate: yup.date().required(),
  maxDate: yup.date().required(),
  placeId: yup.string().uuid().required()
})
