import { Field, Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import { userCreateSchema, userUpdateSchema } from '../../../util/schemas'

export default function UserForm ({
  isCreating,
  initialValues,
  onSubmit,
  onCancel
}) {
  const handleValidate = values => {
    const errors = {}

    if (isCreating) {
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Digite a sua senha novamente'
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'As senhas digitadas não conferem'
      }
    }

    return errors
  }

  const handleSubmit = values => {
    const { confirmPassword, ...rest } = values
    onSubmit(rest)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isCreating ? userCreateSchema : userUpdateSchema}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting, errors, touched, values, setFieldValue }) => (
        <FormikForm>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Nome</Form.Label>
            <Field
              as={Form.Control}
              name='name'
              placeholder='Nome'
              isInvalid={touched.name && errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {touched.name && errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>E-mail</Form.Label>
            <Field
              as={Form.Control}
              name='email'
              placeholder='E-mail'
              type='email'
              isInvalid={touched.email && errors.email}
            />
            <Form.Control.Feedback type='invalid'>
              {touched.email && errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          {isCreating && (
            <>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Senha</Form.Label>
                <Field
                  as={Form.Control}
                  name='password'
                  placeholder='Senha'
                  type='password'
                  isInvalid={touched.password && errors.password}
                />
                <Form.Control.Feedback type='invalid'>
                  {touched.password && errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Confirmar Senha</Form.Label>
                <Field
                  as={Form.Control}
                  name='confirmPassword'
                  placeholder='Confirmar Senha'
                  type='password'
                  isInvalid={touched.confirmPassword && errors.confirmPassword}
                />
                <Form.Control.Feedback type='invalid'>
                  {touched.confirmPassword && errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Sócio</Form.Label>
            <Field as={Form.Select} name='isOwner'>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </Field>
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Salvando…' : 'Salvar'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Cancelar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

UserForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
