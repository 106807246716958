import dayjs from 'dayjs'
import { Form as FormikForm, Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { invoiceReportSchema } from '../../../util/schemas'
import PlaceField from '../../atoms/PlaceField'
import MonthSelectField from '../../molecules/MonthSelect/MonthSelectField'

const monthStart = dayjs()
  .subtract(1, 'month')
  .startOf('month')

export default function InvoiceReportForm ({
  initialValues,
  onSubmit,
  onCancel,
  places
}) {
  const handleSelectPlace = setFieldValue => event => {
    setFieldValue('placeId', event.target.value)
  }

  return (
    <Formik
      initialValues={
        initialValues || {
          minDate: monthStart.toDate(),
          maxDate: monthStart.date(monthStart.daysInMonth()).toDate(),
          peopleIds: null
        }
      }
      validationSchema={invoiceReportSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, values, setFieldValue }) => (
        <FormikForm>
          <Form.Group className='mb-3'>
            <Form.Label>Período</Form.Label>
            <MonthSelectField />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Estabelecimento</Form.Label>
            <PlaceField
              places={places}
              value={values.placeId}
              onChange={handleSelectPlace(setFieldValue)}
            />
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Gerando…' : 'Gerar relatório'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Voltar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}
