import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { Badge, Col, Form, Row } from 'react-bootstrap'
import PersonField from '../../atoms/PersonField'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import AllocationCard from '../../molecules/AllocationCard'

export default function AllocationsList ({
  title,
  headcount,
  allocations,
  people,
  availablePeople,
  onSelectPerson,
  onClickCrown,
  onClickUnbench,
  onClickDelete,
  onChangeDelay
}) {
  const [selectedPerson, setSelectedPerson] = useState(null)
  const getPerson = useCallback(
    id => people?.find(person => person.id === id),
    [people]
  )

  const sortedAllocations = useMemo(
    () =>
      _.orderBy(
        allocations,
        ['isLeader', allocation => getPerson(allocation.personId)?.name],
        ['desc', 'asc']
      ),
    [allocations, getPerson]
  )

  if (!allocations || !people) {
    return <Spinner />
  }

  const canAdd = headcount === -1 || allocations.length < headcount

  const handleSelectPerson = async event => {
    const personId = event.target.value
    setSelectedPerson(personId)
    await onSelectPerson(personId)
    setSelectedPerson(null)
  }

  return (
    <>
      <Title>
        {title}{' '}
        <Badge bg='secondary'>
          {allocations.length}/{~headcount ? headcount : '∞'}
        </Badge>
      </Title>
      {onSelectPerson && canAdd && (
        <Form.Group className='mb-3'>
          <Form.Label>Pessoal disponível:</Form.Label>
          <PersonField
            people={availablePeople}
            value={selectedPerson}
            onChange={handleSelectPerson}
          />
        </Form.Group>
      )}
      <Row className='g-4'>
        {sortedAllocations.map(allocation => {
          const person = getPerson(allocation.personId)

          return (
            <Col key={allocation.personId} md={12}>
              <AllocationCard
                person={person}
                isLeader={allocation.isLeader}
                delay={allocation.delay}
                onClickCrown={
                  !allocation.isLeader && onClickCrown
                    ? () => onClickCrown(person)
                    : null
                }
                onClickUnbench={
                  onClickUnbench ? () => onClickUnbench(person) : null
                }
                onClickDelete={
                  onClickDelete ? () => onClickDelete(person) : null
                }
                onChangeDelay={
                  onChangeDelay ? delay => onChangeDelay(person, delay) : null
                }
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

AllocationsList.propTypes = {
  allocations: PropTypes.arrayOf(PropTypes.object),
  people: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.node,
  headcount: PropTypes.number,
  onSelectPerson: PropTypes.func,
  onClickCrown: PropTypes.func,
  onClickUnbench: PropTypes.func,
  onClickDelete: PropTypes.func,
  onChangeDelay: PropTypes.func
}
