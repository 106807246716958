import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDate } from '../../../util/formatters'
import {
  useAllocationsQuery,
  usePeopleQuery,
  usePlaceQuery
} from '../../../util/queries'
import useAuth from '../../../util/useAuth'
import Title from '../../atoms/Title'
import AllocationsCalendar from '../../molecules/AllocationsCalendar'
import AllocationsList from '../../organisms/AllocationsList'
import { Header } from '../../organisms/Header'
import { AllocatorTemplate } from '../../templates/AllocatorTemplate'

export default function AllocationsPage () {
  const navigate = useNavigate()
  const { id: placeId } = useParams()
  const [selectedDate, setSelectedDate] = useState(new Date())

  const { me } = useAuth()
  const { data: place } = usePlaceQuery(placeId)
  const { data: people } = usePeopleQuery()
  const {
    data: allocations,
    allocate,
    disallocate,
    crown
  } = useAllocationsQuery({
    minDate: formatDate(dayjs(selectedDate).startOf('month')),
    maxDate: formatDate(dayjs(selectedDate).endOf('month'))
  })

  const matchesDate = useCallback(
    allocation => allocation.date === formatDate(selectedDate),
    [selectedDate]
  )

  const placeAllocations = useMemo(
    () => allocations?.filter(allocation => allocation.placeId === placeId),
    [allocations, placeId]
  )

  const mainAllocations = useMemo(
    () =>
      placeAllocations?.filter(
        allocation => matchesDate(allocation) && !allocation.isBench
      ),
    [placeAllocations, matchesDate]
  )
  const benchAllocations = useMemo(
    () =>
      placeAllocations?.filter(
        allocation => matchesDate(allocation) && allocation.isBench
      ),
    [placeAllocations, matchesDate]
  )

  const availablePeopleForMain = useMemo(() => {
    const allocationsMatchingDate = allocations?.filter(matchesDate)
    return people?.filter(
      person =>
        !allocationsMatchingDate?.find(
          allocation => allocation.personId === person.id && !allocation.isBench
        )
    )
  }, [people, allocations, matchesDate])
  const availablePeopleForBench = useMemo(
    () =>
      availablePeopleForMain?.filter(
        person =>
          !benchAllocations?.find(
            allocation => allocation.personId === person.id
          )
      ),
    [availablePeopleForMain, benchAllocations]
  )

  const handleSelectPerson = async (personId, isBench) => {
    await allocate({
      date: formatDate(selectedDate),
      placeId,
      personId,
      isLeader:
        !isBench && !mainAllocations?.find(allocation => allocation.isLeader),
      isBench
    })
  }

  const handleClickDelete = async person => {
    await disallocate({
      date: formatDate(selectedDate),
      placeId,
      personId: person.id
    })
  }

  const handleClickCrown = async person => {
    await crown({
      date: formatDate(selectedDate),
      placeId,
      personId: person.id
    })
  }

  const handleClickUnbench = async person => {
    await allocate({
      date: formatDate(selectedDate),
      placeId,
      personId: person.id,
      isLeader: !mainAllocations?.find(allocation => allocation.isLeader),
      isBench: false
    })
  }

  const handleChangeDelay = async (person, delay) => {
    await allocate({
      date: formatDate(selectedDate),
      placeId,
      personId: person.id,
      isLeader: mainAllocations?.find(
        allocation => allocation.personId === person.id
      )?.isLeader,
      isBench: false,
      delay
    })
  }

  const handleClickBack = () => {
    navigate('/places')
  }

  const isAllocationComplete = mainAllocations?.length >= place?.headcount
  const canAllocate =
    me?.isOwner ||
    me?.roles?.find(
      role => role.placeId === placeId && role.role === 'allocator'
    )

  return (
    <AllocatorTemplate
      header={<Header />}
      title={
        <Title onClickBack={handleClickBack}>
          {!place ? 'Escala' : `Escala de ${place?.name}`}
        </Title>
      }
      calendar={
        <AllocationsCalendar
          allocations={placeAllocations}
          headcount={place?.headcount}
          value={selectedDate}
          onChange={setSelectedDate}
        />
      }
      lists={[
        <AllocationsList
          title='Efetivo'
          headcount={place?.headcount}
          allocations={mainAllocations}
          people={people}
          availablePeople={availablePeopleForMain}
          onSelectPerson={
            canAllocate ? personId => handleSelectPerson(personId, false) : null
          }
          onClickDelete={canAllocate ? handleClickDelete : null}
          onClickCrown={canAllocate ? handleClickCrown : null}
          onChangeDelay={canAllocate ? handleChangeDelay : null}
        />,
        <AllocationsList
          title='Suplentes'
          headcount={-1}
          allocations={benchAllocations}
          people={people}
          availablePeople={availablePeopleForBench}
          onSelectPerson={
            canAllocate ? personId => handleSelectPerson(personId, true) : null
          }
          onClickDelete={canAllocate ? handleClickDelete : null}
          onClickUnbench={
            canAllocate && !isAllocationComplete ? handleClickUnbench : null
          }
        />
      ]}
    />
  )
}
