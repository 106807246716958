import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import HorizontalCard from '../HorizontalCard'

export default function PersonCard ({ person, onClickEdit, onClickDelete }) {
  return (
    <HorizontalCard
      photoUrl={person.photoUrl}
      title={person.name}
      text={person.department}
    >
      <Button variant='secondary' onClick={onClickEdit}>
        Editar
      </Button>{' '}
      <Button variant='danger' onClick={onClickDelete}>
        Excluir
      </Button>
    </HorizontalCard>
  )
}

PersonCard.propTypes = {
  person: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
}
