import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { DEFAULT_PHOTO } from '../../../util/constants'
import uploadFile from '../../../util/uploadFile'
import Spinner from '../Spinner'

export default function PhotoField ({ value, onChange }) {
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()

  const handleAdd = () => {
    inputRef.current.click()
  }

  const handleChange = async () => {
    setLoading(true)
    const file = inputRef.current.files[0]
    if (file) {
      const url = await uploadFile(file)
      if (url) onChange?.(url)
    }
    inputRef.current.value = null
    setLoading(false)
  }

  const handleClear = () => {
    onChange?.(null)
  }

  return (
    <div className='d-flex align-items-center flex-column'>
      <input
        className='d-none'
        ref={inputRef}
        type='file'
        accept='image/*'
        onChange={handleChange}
      />
      {loading ? (
        <Spinner />
      ) : (
        <img
          src={value || DEFAULT_PHOTO}
          alt='Foto'
          className='mb-1'
          width={150}
        />
      )}
      <Button variant='secondary' onClick={handleAdd}>
        {value ? 'Alterar' : 'Adicionar'} foto
      </Button>
      {value && (
        <Button variant='danger' className='mt-1' onClick={handleClear}>
          Remover
        </Button>
      )}
    </div>
  )
}

PhotoField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}
