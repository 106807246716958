import PropTypes from 'prop-types'
import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { USER_ROLE_LABELS } from '../../../util/constants'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'

export default function RolesList ({
  roles,
  places,
  onClickNew,
  onClickDelete,
  onClickBack
}) {
  if (!roles || !places) {
    return <Spinner />
  }

  const getPlaceById = placeId =>
    places.find(place => place.id === placeId)?.name

  return (
    <>
      <Title
        onClickBack={onClickBack}
        actions={
          <Button variant='success' onClick={onClickNew}>
            Novo
          </Button>
        }
      >
        Cargos
      </Title>
      <Table>
        <thead>
          <tr>
            <th>Cargo</th>
            <th>Estabelecimento</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {roles.map(role => (
            <tr key={role.placeId}>
              <td>{USER_ROLE_LABELS[role.role]}</td>
              <td>{getPlaceById(role.placeId)}</td>
              <td>
                <Button variant='danger' onClick={() => onClickDelete?.(role)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

RolesList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  places: PropTypes.arrayOf(PropTypes.object),
  onClickNew: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickBack: PropTypes.func
}
