import { useNavigate } from 'react-router-dom'
import { useDeletePlaceMutation, usePlacesQuery } from '../../../util/queries'
import useAuth from '../../../util/useAuth'
import useModalFlow from '../../../util/useModalFlow'
import DeleteModal from '../../molecules/DeleteModal'
import { Header } from '../../organisms/Header'
import PlacesList from '../../organisms/PlacesList'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function PlacesPage () {
  const navigate = useNavigate()
  const { me } = useAuth()
  const { data: places } = usePlacesQuery()

  const deletePlace = useDeletePlaceMutation()
  const { modalProps, setItem } = useModalFlow(item => deletePlace(item.id))

  const handleClickNew = () => {
    navigate('/places/new')
  }

  const handleClickEdit = place => {
    navigate(`/places/${place.id}`)
  }

  const handleClickDelete = place => {
    setItem(place)
  }

  const handleClickAllocations = place => {
    navigate(`/places/${place.id}/allocations`)
  }

  const canModify = me?.isOwner

  return (
    <DefaultTemplate header={<Header />}>
      <PlacesList
        places={places}
        onClickNew={canModify ? handleClickNew : null}
        onClickEdit={canModify ? handleClickEdit : null}
        onClickDelete={canModify ? handleClickDelete : null}
        onClickAllocations={handleClickAllocations}
      />
      <DeleteModal {...modalProps} />
    </DefaultTemplate>
  )
}
