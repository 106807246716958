import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

export default function DelayModal ({ delay, show, onConfirm, onClose }) {
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    await onConfirm(+inputRef.current.value)
    setLoading(false)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleConfirm()
    }
  }

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    if (delay != null) {
      inputRef.current.value = delay
    }

    inputRef.current.focus()
    inputRef.current.select()
  }, [show, delay])

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registrar atraso</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Atraso (minutos)</Form.Label>
          <Form.Control
            ref={inputRef}
            type='number'
            min={0}
            max={720}
            placeholder='0'
            onKeyDown={handleKeyDown}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancelar
        </Button>
        <Button variant='primary' onClick={handleConfirm} disabled={loading}>
          {loading ? 'Salvando…' : 'Salvar atraso'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DelayModal.propTypes = {
  delay: PropTypes.number,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
}
