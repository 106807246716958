import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { DEFAULT_PHOTO } from '../../../util/constants'
import styles from './styles.module.css'

export default function HorizontalCard ({
  photoUrl,
  title,
  text,
  children,
  onClickPhoto
}) {
  const clickableProps = {
    role: 'button',
    style: { cursor: 'pointer' },
    onClick: onClickPhoto
  }

  return (
    <Card className={styles.card}>
      <img
        className={styles.cardImage}
        alt={`Foto de ${title}`}
        src={photoUrl || DEFAULT_PHOTO}
        width={100}
        {...(onClickPhoto ? clickableProps : {})}
      />
      <Card.Body className={styles.cardBody}>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        {children}
      </Card.Body>
    </Card>
  )
}

HorizontalCard.propTypes = {
  photoUrl: PropTypes.string,
  title: PropTypes.node.isRequired,
  text: PropTypes.string,
  onClickPhoto: PropTypes.func
}
