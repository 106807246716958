import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

export default function PlaceField ({ places, value, onChange, ...props }) {
  return (
    <Form.Select value={value || ''} onChange={onChange} {...props}>
      <option value=''>Selecione…</option>
      {places?.map(place => (
        <option key={place.id} value={place.id}>
          {place.name}
        </option>
      ))}
    </Form.Select>
  )
}

PlaceField.propTypes = {
  ...Form.Select.propTypes,
  places: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  onChange: PropTypes.func
}
