import dayjs from 'dayjs'
import { useField } from 'formik'
import MonthSelect from '.'

export default function MonthSelectField ({ ...props }) {
  const [, meta, minHelpers] = useField('minDate')
  const [, , maxHelpers] = useField('maxDate')

  const handleChange = value => {
    const date = dayjs(value)
    minHelpers.setValue(date.date(1).toDate())
    maxHelpers.setValue(date.endOf('month').toDate())
  }

  const value = meta.value

  return <MonthSelect value={value ?? ''} onChange={handleChange} {...props} />
}
