import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import HorizontalCard from '../HorizontalCard'

export default function UserCard ({
  user,
  isCurrentUser,
  onClickRoles,
  onClickEdit,
  onClickDelete
}) {
  return (
    <HorizontalCard
      title={user.name}
      text={isCurrentUser ? 'Você' : user.isOwner ? 'Sócio' : 'Não sócio'}
    >
      {!user.isOwner && (
        <>
          <Button variant='primary' onClick={onClickRoles}>
            Ver cargos
          </Button>{' '}
        </>
      )}
      <Button variant='secondary' onClick={onClickEdit}>
        Editar
      </Button>
      {!isCurrentUser && (
        <>
          {' '}
          <Button
            variant='danger'
            onClick={onClickDelete}
            disabled={!onClickDelete}
          >
            Excluir
          </Button>
        </>
      )}
    </HorizontalCard>
  )
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool,
  onClickRoles: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
}
