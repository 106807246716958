import _ from 'lodash'
import qs from 'qs'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDate } from '../../../util/formatters'
import { usePlacesQuery } from '../../../util/queries'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import { InvoiceReportForm } from '../../organisms/InvoiceReport'
import { PaymentReportForm } from '../../organisms/PaymentReport'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function ReportFormPage () {
  const navigate = useNavigate()
  const { form } = useParams()
  const { data: places } = usePlacesQuery()

  const handleCancel = () => {
    navigate('/reports')
  }

  const handleSubmit = data => {
    const formattedData = _.mapValues(data, value =>
      value instanceof Date ? formatDate(value) : value
    )
    navigate(`./view?${qs.stringify(formattedData)}`)
  }

  const templates = {
    payment: {
      title: 'Folha de pagamento',
      form: (
        <PaymentReportForm onSubmit={handleSubmit} onCancel={handleCancel} />
      )
    },
    invoice: {
      title: 'Fatura de estabelecimento',
      form: (
        <InvoiceReportForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          places={places}
        />
      )
    }
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title onClickBack={handleCancel}>{templates[form].title}</Title>
      {templates[form].form}
    </DefaultTemplate>
  )
}
