import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeletePersonMutation, usePeopleQuery } from '../../../util/queries'
import useModalFlow from '../../../util/useModalFlow'
import DeleteModal from '../../molecules/DeleteModal'
import { Header } from '../../organisms/Header'
import PeopleList from '../../organisms/PeopleList'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function PeoplePage () {
  const navigate = useNavigate()
  const { data: people } = usePeopleQuery()

  const deletePerson = useDeletePersonMutation()
  const { modalProps, setItem } = useModalFlow(item => deletePerson(item.id))

  const handleClickNew = () => {
    navigate('/people/new')
  }

  const handleClickEdit = place => {
    navigate(`/people/${place.id}`)
  }

  const handleClickDelete = place => {
    setItem(place)
  }

  return (
    <DefaultTemplate header={<Header />}>
      <PeopleList
        people={people}
        onClickNew={handleClickNew}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <DeleteModal {...modalProps} />
    </DefaultTemplate>
  )
}
