import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAuth from '../../../util/useAuth'

export function Header () {
  const { me, signOut } = useAuth()
  const isAllocator = me?.roles?.find(role => role.role === 'allocator')

  return (
    <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          Maxter
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/places'>
              Estabelecimentos
            </Nav.Link>
            {(me.isOwner || isAllocator) && (
              <Nav.Link as={Link} to='/people'>
                Pessoal
              </Nav.Link>
            )}
            <Nav.Link as={Link} to='/reports'>
              Relatórios
            </Nav.Link>
            {me.isOwner && (
              <Nav.Link as={Link} to='/users'>
                Usuários
              </Nav.Link>
            )}
          </Nav>
          <Nav>
            <NavDropdown title={me.name} id='collasible-nav-dropdown'>
              {/* <NavDropdown.Item as={Link} to='/settings'>
                Configurações
              </NavDropdown.Item>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item onClick={signOut}>Sair</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
