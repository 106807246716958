import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import formatDelay from '../../../util/formatDelay'
import { formatCurrency, formatDate } from '../../../util/formatters'
import getDelayDiscount from '../../../util/getDelayDiscount'
import Spinner from '../../atoms/Spinner'
import styles from './styles.module.css'

export default function InvoiceReportView ({
  tableRef,
  placeId,
  allocations,
  places
}) {
  const place = useMemo(
    () => (places ? _.find(places, place => place.id === placeId) : null),
    [places, placeId]
  )

  const allocationsByDate = useMemo(
    () =>
      place &&
      _.chain(allocations)
        .filter(allocation => !allocation.isBench)
        .map(allocation => ({
          ...allocation,
          price:
            place.retailPrice -
            getDelayDiscount(place.retailPrice, allocation.delay ?? 0)
        }))
        .groupBy(allocation => dayjs(allocation.date).date())
        .mapValues(list => ({
          date: formatDate(dayjs(list[0].date)),
          headcount: list.length,
          revenue: _.sumBy(list, 'price'),
          delays: _.sumBy(list, item => item.delay ?? 0)
        }))
        .sortBy('date')
        .value(),
    [allocations, place]
  )

  const revenue = useMemo(
    () => _.sumBy(allocationsByDate, 'revenue'),
    [allocationsByDate]
  )

  if (!place && !allocations) {
    return <Spinner />
  }

  return (
    <div className={styles.wrapper}>
      <table ref={tableRef}>
        <tbody>
          <tr>
            <th colSpan={4}>{place.name}</th>
          </tr>
          <tr>
            <th colSpan={2}>Efetivo</th>
            <th colSpan={1}>Diária</th>
            <th colSpan={1}>Total</th>
          </tr>
          <tr>
            <td colSpan={2}>{place.headcount} seguranças/dia</td>
            <td colSpan={1}>R$ {formatCurrency(place.retailPrice)}</td>
            <td colSpan={1}>R$ {formatCurrency(revenue)}</td>
          </tr>
          <tr>
            <td colSpan={4} />
          </tr>
          <tr>
            <th>Data</th>
            <th>Efetivo</th>
            <th>Atrasos</th>
            <th>Subtotal</th>
          </tr>
          {_.map(allocationsByDate, row => (
            <tr key={row.date} className={styles.row}>
              <td>{formatDate(row.date)}</td>
              <td className={styles.headcountCell}>{row.headcount}</td>
              <td>{formatDelay(row.delays)}</td>
              <td>R$ {formatCurrency(row.revenue)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

InvoiceReportView.propTypes = {
  tableRef: PropTypes.object,
  placeId: PropTypes.string,
  places: PropTypes.arrayOf(PropTypes.object),
  allocations: PropTypes.arrayOf(PropTypes.object)
}
