import axios from 'axios'
import { TOKEN_STORAGE_KEY } from './constants'

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

apiAxios.interceptors.request.use(config => {
  const token = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export default apiAxios
