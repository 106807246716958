import axios from 'axios'

const uploadUrl = process.env.REACT_APP_UPLOAD_URL

export default async function uploadFile (file, preset = 'person_photo') {
  const formData = new FormData()

  formData.append('upload_preset', preset)
  formData.append('file', file)

  try {
    const response = await axios.post(uploadUrl, formData)
    return response.data.secure_url
  } catch (err) {
    console.error(err)
    return null
  }
}
