import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.css'

export default function Title ({ small, children, actions, onClickBack }) {
  const content = (
    <div className='d-flex align-items-center flex-wrap'>
      {onClickBack && (
        <div className={styles.backIcon} role='button' onClick={onClickBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      )}
      <div className='flex-grow-1'>{children}</div>
      {actions}
    </div>
  )

  if (small) {
    return <h2 className='mt-2 mb-2'>{content}</h2>
  }

  return <h1 className='mt-4 mb-4'>{content}</h1>
}

Title.propTypes = {
  small: PropTypes.bool,
  actions: PropTypes.node,
  onClickBack: PropTypes.func
}
