import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function DeleteModal ({ show, onConfirm, onClose }) {
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    await onConfirm()
    setLoading(false)
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Excluir</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Tem certeza que deseja excluir esse item? Essa ação não poderá ser
          desfeita.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Não, retornar
        </Button>
        <Button variant='danger' onClick={handleConfirm} disabled={loading}>
          {loading ? 'Excluindo…' : 'Sim, excluir'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DeleteModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
}
