import * as _ from 'lodash'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlacesQuery, useRolesQuery } from '../../../util/queries'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import RoleForm from '../../organisms/RoleForm'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

const userRoleSkel = {
  role: 'manager',
  placeId: ''
}

export default function UserRoleEditorPage () {
  const navigate = useNavigate()
  const { id: userId } = useParams()
  const { data: roles, set } = useRolesQuery({ userId })

  const { data: places } = usePlacesQuery()
  const availablePlaces = useMemo(() => {
    const usedPlaceIds = _.map(roles, 'placeId')
    return _.filter(places, place => !usedPlaceIds.includes(place.id))
  }, [places, roles])

  const handleCancel = () => {
    navigate(`/users/${userId}/roles`)
  }

  const handleSubmit = async formData => {
    await set(formData)
    handleCancel()
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title onClickBack={handleCancel}>Novo cargo</Title>
      {!places ? (
        <Spinner />
      ) : (
        <RoleForm
          initialValues={userRoleSkel}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          places={availablePlaces}
        />
      )}
    </DefaultTemplate>
  )
}
