import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Accordion, Button, Form } from 'react-bootstrap'
import { personSchema } from '../../../util/schemas'
import FormField from '../../atoms/FormField'
import PhotoField from '../../atoms/PhotoField'

export default function PersonForm ({ initialValues, onSubmit, onCancel }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={personSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, values, setFieldValue }) => (
        <FormikForm>
          <Form.Group className='mb-3'>
            <PhotoField
              value={values.photoUrl}
              onChange={value => setFieldValue('photoUrl', value)}
            />
          </Form.Group>
          <Accordion defaultActiveKey='0' className='mb-4'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Dados militares</Accordion.Header>
              <Accordion.Body>
                <Form.Group className='mb-3'>
                  <Form.Label>Nome de guerra*</Form.Label>
                  <FormField name='name' />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>BP/Cia*</Form.Label>
                  <FormField name='department' />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Graduação/Patente</Form.Label>
                  <FormField as={Form.Select} name='graduation'>
                    <option value={null}>Nenhuma</option>
                    <optgroup label='Graduações'>
                      <option>Soldado</option>
                      <option>Cabo</option>
                      <option>3º Sargento</option>
                      <option>2º Sargento</option>
                      <option>1º Sargento</option>
                      <option>Subtente</option>
                    </optgroup>
                    <optgroup label='Patentes'>
                      <option>2º Tenetene</option>
                      <option>1º Tenetene</option>
                      <option>Capitão</option>
                      <option>Major</option>
                      <option>Tenente-coronel</option>
                      <option>Coronel</option>
                    </optgroup>
                  </FormField>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Cursos</Form.Label>
                  <FormField as='textarea' name='courses' />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>Dados pessoais</Accordion.Header>
              <Accordion.Body>
                <Form.Group className='mb-3'>
                  <Form.Label>Nome completo</Form.Label>
                  <FormField name='fullName' />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>WhatsApp</Form.Label>
                  <FormField name='phone' valueType='phone' />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>CPF</Form.Label>
                  <FormField name='cpf' valueType='cpf' />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Dados bancários</Form.Label>
                  <FormField as='textarea' name='bankingDetails' />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Salvando…' : 'Salvar'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Cancelar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

PersonForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
