import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import PersonCard from '../../molecules/PersonCard'

export default function PeopleList ({
  people,
  onClickNew,
  onClickEdit,
  onClickDelete
}) {
  const sortedPeople = useMemo(() => _.sortBy(people, 'name'), [people])

  if (!people) {
    return <Spinner />
  }

  return (
    <>
      <Title
        actions={
          <Button variant='success' onClick={onClickNew}>
            Novo
          </Button>
        }
      >
        Pessoal
      </Title>
      <Row className='g-4'>
        {sortedPeople.map(person => (
          <Col key={person.id} md={12}>
            <PersonCard
              person={person}
              onClickEdit={() => onClickEdit?.(person)}
              onClickDelete={() => onClickDelete?.(person)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

PeopleList.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  onClickNew: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
}
