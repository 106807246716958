import React, { useEffect } from 'react'
import useAuth from '../../../util/useAuth'
import Spinner from '../../atoms/Spinner'

export function LoaderPage () {
  const { fetchMe } = useAuth()

  useEffect(fetchMe, [fetchMe])

  return <Spinner />
}
