import dayjs from 'dayjs'
import _ from 'lodash'
import qs from 'qs'
import { useMemo, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  useAllocationsQuery,
  usePeopleQuery,
  usePlacesQuery
} from '../../../util/queries'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import {
  exportInvoiceReportToExcel,
  InvoiceReportView
} from '../../organisms/InvoiceReport'
import {
  exportPaymentReportToExcel,
  PaymentReportView
} from '../../organisms/PaymentReport'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function ReportViewPage () {
  const tableRef = useRef()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { form } = useParams()
  const params = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [
    search
  ])

  const { data: people } = usePeopleQuery()
  const { data: places } = usePlacesQuery()
  const { data: allocations } = useAllocationsQuery({
    minDate: params.minDate,
    maxDate: params.maxDate,
    placeId: params.placeId
  })

  const handleBack = () => {
    navigate('..')
  }

  const handleExport = () => {
    if (!tableRef.current) return

    const suffix = dayjs(params.minDate).format('MMMM YYYY')
    const exporter = {
      payment: () => exportPaymentReportToExcel(tableRef.current, suffix),
      invoice: () => {
        const place = _.find(places, place => place.id === params.placeId)
        exportInvoiceReportToExcel(tableRef.current, place.name, suffix)
      }
    }[form]

    exporter()
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title
        onClickBack={handleBack}
        actions={
          <>
            <Button
              variant='primary'
              onClick={handleExport}
              disabled={!allocations?.length}
            >
              Exportar para Excel
            </Button>
          </>
        }
      >
        Relatório
      </Title>

      {
        {
          payment: (
            <PaymentReportView
              tableRef={tableRef}
              people={people}
              places={places}
              allocations={allocations}
            />
          ),
          invoice: (
            <InvoiceReportView
              tableRef={tableRef}
              placeId={params.placeId}
              places={places}
              allocations={allocations}
            />
          )
        }[form]
      }
    </DefaultTemplate>
  )
}
