import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'

export default function PersonField ({ people, value, onChange, ...props }) {
  return (
    <Form.Select value={value || ''} onChange={onChange} {...props}>
      <option value=''>Selecione…</option>
      {people?.map(person => (
        <option key={person.id} value={person.id}>
          {person.name} ({person.department})
        </option>
      ))}
    </Form.Select>
  )
}

PersonField.propTypes = {
  ...Form.Select.propTypes,
  people: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  onChange: PropTypes.func
}
