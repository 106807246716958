import { faClock, faCrown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import formatDelay from '../../../util/formatDelay'
import useModalFlow from '../../../util/useModalFlow'
import DelayModal from '../DelayModal'
import HorizontalCard from '../HorizontalCard'
import PhotoModal from '../PhotoModal'
import styles from './styles.module.css'
export default function AllocationCard ({
  person,
  isLeader,
  onClickCrown,
  onClickUnbench,
  onClickDelete,
  delay,
  onChangeDelay
}) {
  const [isDeleting, setIsDeleting] = useState()
  const { modalProps: photoModalProps, setItem: setPhotoItem } = useModalFlow()
  const { modalProps: delayModalProps, setItem: setDelayItem } = useModalFlow(
    (_, input) => onChangeDelay(input)
  )

  const handleClickDelete = async () => {
    setIsDeleting(true)
    await onClickDelete()
    setIsDeleting(false)
  }

  const handleClickView = () => {
    setPhotoItem(true)
  }

  const handleClickDelay = () => {
    setDelayItem(true)
  }

  return (
    <>
      <HorizontalCard
        photoUrl={person.photoUrl}
        onClickPhoto={handleClickView}
        title={
          <div className={styles.name}>
            {person.name}
            {isLeader && (
              <>
                {' '}
                <Badge bg='primary' className={styles.leaderBadge}>
                  <FontAwesomeIcon icon={faCrown} /> Líder
                </Badge>
              </>
            )}
          </div>
        }
        text={person.department}
      >
        {onClickCrown && (
          <>
            <Button title='Tornar líder' variant='light' onClick={onClickCrown}>
              <FontAwesomeIcon icon={faCrown} />
            </Button>{' '}
          </>
        )}
        {onClickUnbench && (
          <>
            <Button variant='light' onClick={onClickUnbench}>
              Tornar efetivo
            </Button>{' '}
          </>
        )}
        {onChangeDelay && (
          <>
            <Button
              title='Registrar atraso'
              variant={delay ? 'danger' : 'light'}
              onClick={handleClickDelay}
            >
              <FontAwesomeIcon icon={faClock} />
              {delay ? ` ${formatDelay(delay)}` : null}
            </Button>{' '}
          </>
        )}
        {onClickDelete && (
          <Button
            variant='light'
            onClick={handleClickDelete}
            disabled={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </HorizontalCard>
      <PhotoModal person={person} {...photoModalProps} />
      <DelayModal delay={delay} {...delayModalProps} />
    </>
  )
}

AllocationCard.propTypes = {
  person: PropTypes.object.isRequired,
  isLeader: PropTypes.bool.isRequired,
  delay: PropTypes.number,
  onClickCrown: PropTypes.func,
  onClickUnbench: PropTypes.func,
  onClickDelete: PropTypes.func,
  onSetDelay: PropTypes.func
}
