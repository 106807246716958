import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import { USER_ROLE_LABELS } from '../../../util/constants'
import { userRoleSchema } from '../../../util/schemas'
import FormField from '../../atoms/FormField'

export default function RoleForm ({
  initialValues,
  onSubmit,
  onCancel,
  places
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userRoleSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <FormikForm>
          <Form.Group className='mb-3'>
            <Form.Label>Cargo</Form.Label>
            <FormField as={Form.Select} name='role'>
              {Object.keys(USER_ROLE_LABELS).map(value => (
                <option key={value} value={value}>
                  {USER_ROLE_LABELS[value]}
                </option>
              ))}
            </FormField>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Estabelecimento</Form.Label>
            <FormField as={Form.Select} name='placeId'>
              {places ? (
                places.length ? (
                  <>
                    <option value='' />
                    {places.map(place => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled>Nenhum estabelecimento disponível.</option>
                )
              ) : (
                <option disabled>Carregando estabelecimentos…</option>
              )}
            </FormField>
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Salvando…' : 'Salvar'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Cancelar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

RoleForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  places: PropTypes.arrayOf(PropTypes.object)
}
