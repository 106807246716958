import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import PlaceCard from '../../molecules/PlaceCard'

export default function PlacesList ({
  places,
  onClickNew,
  onClickEdit,
  onClickDelete,
  onClickAllocations
}) {
  const sortedPlaces = useMemo(() => _.sortBy(places, 'name'), [places])

  if (!places) {
    return <Spinner />
  }

  return (
    <>
      <Title
        actions={
          onClickNew && (
            <Button variant='success' onClick={onClickNew}>
              Novo
            </Button>
          )
        }
      >
        Estabelecimentos
      </Title>
      <Row className='g-4'>
        {sortedPlaces.map(place => (
          <Col key={place.id} sm={12} md={6}>
            <PlaceCard
              place={place}
              onClickEdit={onClickEdit && (() => onClickEdit?.(place))}
              onClickDelete={onClickDelete && (() => onClickDelete?.(place))}
              onClickAllocations={
                onClickAllocations && (() => onClickAllocations?.(place))
              }
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

PlacesList.propTypes = {
  places: PropTypes.arrayOf(PropTypes.object),
  onClickNew: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickAllocations: PropTypes.func
}
