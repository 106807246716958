import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'

export function SignInForm ({ onSubmit, error }) {
  const [data, setData] = useState({
    username: '',
    password: ''
  })

  const handleChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    onSubmit(data)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <Alert variant='danger' dismissible>
          <Alert.Heading>Oops!</Alert.Heading>
          <p>Usuário e/ou senha inválidos.</p>
        </Alert>
      )}
      <Form.Group className='mb-3' controlId='formBasicEmail'>
        <Form.Label>E-mail</Form.Label>
        <Form.Control
          type='email'
          placeholder='E-mail'
          name='username'
          onChange={handleChange}
          value={data.username}
        />
      </Form.Group>

      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Label>Senha</Form.Label>
        <Form.Control
          type='password'
          placeholder='Senha'
          name='password'
          onChange={handleChange}
          value={data.password}
        />
      </Form.Group>
      <Button variant='primary' type='submit'>
        Entrar
      </Button>
    </Form>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object
}
