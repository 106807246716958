import { useState } from 'react'

export default function useModalFlow (onConfirm) {
  const [item, setItem] = useState(null)

  const handleConfirm = async input => {
    await onConfirm?.(item, input)
    setItem(null)
  }

  const handleClose = () => {
    setItem(null)
  }

  const modalProps = {
    show: !!item,
    onConfirm: handleConfirm,
    onClose: handleClose
  }

  return {
    modalProps,
    setItem
  }
}
