import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreatePlaceMutation,
  usePlaceQuery,
  useUpdatePlaceMutation
} from '../../../util/queries'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import PlaceForm from '../../organisms/PlaceForm'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

const placeSkel = {
  name: '',
  personPrice: 0,
  leaderPrice: 0,
  retailPrice: 0,
  headcount: 1
}

export default function PlaceEditorPage () {
  const navigate = useNavigate()
  const { id } = useParams()
  const isCreating = id === 'new'

  const { data: place } = usePlaceQuery(!isCreating ? id : null)
  const createPlace = useCreatePlaceMutation()
  const updatePlace = useUpdatePlaceMutation()

  const initialValues = isCreating ? placeSkel : place

  const handleCancel = () => {
    navigate('/places')
  }

  const handleSubmit = async formData => {
    if (isCreating) {
      await createPlace(formData)
    } else {
      await updatePlace(id, formData)
    }
    handleCancel()
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title onClickBack={handleCancel}>
        {isCreating ? 'Novo estabelecimento' : 'Editar estabelecimento'}
      </Title>
      {!isCreating && !place ? (
        <Spinner />
      ) : (
        <PlaceForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </DefaultTemplate>
  )
}
