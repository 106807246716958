import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Calendar from 'react-calendar'
import { formatDate } from '../../../util/formatters'

export default function AllocationsCalendar ({
  allocations,
  headcount,
  value,
  onChange
}) {
  const headcountByDate = useMemo(
    () =>
      allocations?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.date]: (acc[cur.date] || 0) + 1
        }),
        {}
      ) || {},
    [allocations]
  )

  const getTileClassName = ({ date, view, activeStartDate }) => {
    if (view !== 'month' || !allocations) return 'bg-secondary'
    const isSameMonth = dayjs(date).isSame(value, 'month')
    if (!isSameMonth) return
    const count = headcountByDate[formatDate(date)]
    if (count >= headcount) return 'bg-success'
    if (count > headcount / 2) return 'bg-warning'
    return 'bg-danger'
  }

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    onChange(
      dayjs(activeStartDate)
        .startOf('month')
        .toDate()
    )
  }

  return (
    <div className='d-flex justify-content-center'>
      <Calendar
        value={value}
        onChange={onChange}
        onActiveStartDateChange={handleActiveStartDateChange}
        locale='pt-BR'
        minDetail='year'
        showNeighboringMonth={false}
        tileClassName={params => `${getTileClassName(params)} bg-gradient`}
      />
    </div>
  )
}

AllocationsCalendar.propTypes = {
  allocations: PropTypes.arrayOf(PropTypes.object),
  headcount: PropTypes.number,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
}
