import { createContext, useCallback, useContext, useState } from 'react'
import apiAxios from './apiAxios'
import { TOKEN_STORAGE_KEY } from './constants'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [me, setMe] = useState(null)

  return (
    <AuthContext.Provider value={{ loading, setLoading, me, setMe }}>
      {children({ loading, setLoading, me, setMe })}
    </AuthContext.Provider>
  )
}

export default function useAuth () {
  const { me, setMe, loading, setLoading } = useContext(AuthContext)

  const fetchMe = useCallback(async () => {
    try {
      const response = await apiAxios.get('/me')
      setMe(response.data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [setMe, setLoading])

  const signIn = useCallback(
    async data => {
      const response = await apiAxios.post('/auth/signin', data)

      if (response.data.access_token) {
        window.localStorage.setItem(
          TOKEN_STORAGE_KEY,
          response.data.access_token
        )
      }

      await fetchMe()
    },
    [fetchMe]
  )

  const signOut = async () => {
    await apiAxios.post('/auth/signout')
    window.localStorage.clear(TOKEN_STORAGE_KEY)
    setMe(null)
  }

  return {
    me,
    isLogged: !!me,
    isLoading: loading,
    fetchMe,
    signIn,
    signOut
  }
}
