import { useNavigate } from 'react-router-dom'
import { Header } from '../../organisms/Header'
import ReportsMenu from '../../organisms/ReportsMenu'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function ReportsPage () {
  const navigate = useNavigate()

  const handleChoose = form => {
    navigate(form)
  }

  return (
    <DefaultTemplate header={<Header />}>
      <ReportsMenu onChoose={handleChoose} />
    </DefaultTemplate>
  )
}
