import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import UserCard from '../../molecules/UserCard'

export default function UsersList ({
  users,
  currentUser,
  onClickNew,
  onClickRoles,
  onClickEdit,
  onClickDelete
}) {
  const isCurrentUser = useCallback(user => user.id === currentUser?.id, [
    currentUser
  ])

  const ownerUsers = useMemo(
    () =>
      _.chain(users)
        .filter(user => user.isOwner)
        .sortBy('name')
        .sortBy(user => (isCurrentUser(user) ? -1 : 1))
        .value(),
    [users, isCurrentUser]
  )
  const otherUsers = useMemo(
    () =>
      _.chain(users)
        .filter(user => !user.isOwner)
        .sortBy('name')
        .value(),
    [users]
  )

  if (!users) {
    return <Spinner />
  }

  const renderUsers = users => (
    <Row className='g-4'>
      {users.map(user => (
        <Col key={user.id} md={12}>
          <UserCard
            user={user}
            isCurrentUser={isCurrentUser(user)}
            onClickRoles={() => onClickRoles?.(user)}
            onClickEdit={() => onClickEdit?.(user)}
            onClickDelete={() => onClickDelete?.(user)}
          />
        </Col>
      ))}
    </Row>
  )

  return (
    <>
      <Title
        actions={
          <Button variant='success' onClick={onClickNew}>
            Novo
          </Button>
        }
      >
        Usuários
      </Title>
      <Title small>Sócios</Title>
      {renderUsers(ownerUsers)}
      <Title small>Outros</Title>
      {renderUsers(otherUsers)}
    </>
  )
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  onClickNew: PropTypes.func,
  onClickRoles: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
}
