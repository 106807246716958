import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery
} from '../../../util/queries'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import UserForm from '../../organisms/UserForm'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

const userSkel = {
  name: '',
  email: '',
  password: '',
  isOwner: false
}

export default function UserEditorPage () {
  const navigate = useNavigate()
  const { id } = useParams()
  const isCreating = id === 'new'

  const { data: user } = useUserQuery(!isCreating ? id : null)
  const createUser = useCreateUserMutation()
  const updateUser = useUpdateUserMutation()

  const initialValues = isCreating ? userSkel : user

  const handleCancel = () => {
    navigate('/users')
  }

  const handleSubmit = async formData => {
    if (isCreating) {
      await createUser(formData)
    } else {
      await updateUser(id, formData)
    }
    handleCancel()
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title onClickBack={handleCancel}>
        {isCreating ? 'Novo usuário' : 'Editar usuário'}
      </Title>
      {!isCreating && !user ? (
        <Spinner />
      ) : (
        <UserForm
          isCreating={isCreating}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </DefaultTemplate>
  )
}
