import _ from 'lodash'
import qs from 'qs'
import useSWR, { useSWRConfig } from 'swr'
import apiAxios from './apiAxios'

export const usePlacesQuery = () =>
  useSWR('/places', async () => {
    const response = await apiAxios('/places')
    return response.data
  })

export const usePlaceQuery = id =>
  useSWR(id && `/places/${id}`, async () => {
    const response = await apiAxios(`/places/${id}`)
    return response.data
  })

export const useCreatePlaceMutation = () => {
  const { mutate } = useSWRConfig()

  return async input => {
    await apiAxios.post('/places', input)
    mutate('/places')
  }
}

export const useUpdatePlaceMutation = () => {
  const { mutate } = useSWRConfig()

  return async (id, input) => {
    await apiAxios.put(`/places/${id}`, input)
    mutate('/places')
    mutate(`/places/${id}`)
  }
}

export const useDeletePlaceMutation = () => {
  const { mutate } = useSWRConfig()

  return async id => {
    await apiAxios.delete(`/places/${id}`)
    mutate('/places')
  }
}

export const usePeopleQuery = () =>
  useSWR('/people', async () => {
    const response = await apiAxios('/people')
    return response.data
  })

export const usePersonQuery = id =>
  useSWR(id && `/people/${id}`, async () => {
    const response = await apiAxios(`/people/${id}`)
    return response.data
  })

export const useCreatePersonMutation = () => {
  const { mutate } = useSWRConfig()

  return async input => {
    await apiAxios.post('/people', input)
    mutate('/people')
  }
}

export const useUpdatePersonMutation = () => {
  const { mutate } = useSWRConfig()

  return async (id, input) => {
    await apiAxios.put(`/people/${id}`, input)
    mutate('/people')
    mutate(`/people/${id}`)
  }
}

export const useDeletePersonMutation = () => {
  const { mutate } = useSWRConfig()

  return async id => {
    await apiAxios.delete(`/people/${id}`)
    mutate('/people')
  }
}

export const useUsersQuery = () =>
  useSWR('/users', async () => {
    const response = await apiAxios('/users')
    return response.data
  })

export const useUserQuery = id =>
  useSWR(id && `/users/${id}`, async () => {
    const response = await apiAxios(`/users/${id}`)
    return response.data
  })

export const useCreateUserMutation = () => {
  const { mutate } = useSWRConfig()

  return async input => {
    await apiAxios.post('/users', input)
    mutate('/users')
  }
}

export const useUpdateUserMutation = () => {
  const { mutate } = useSWRConfig()

  return async (id, input) => {
    await apiAxios.put(`/users/${id}`, input)
    mutate('/users')
    mutate(`/users/${id}`)
  }
}

export const useDeleteUserMutation = () => {
  const { mutate } = useSWRConfig()

  return async id => {
    await apiAxios.delete(`/users/${id}`)
    mutate('/users')
  }
}

export const useAllocationsQuery = params => {
  const query = useSWR(`/allocations?${qs.stringify(params)}`, async () => {
    const response = await apiAxios('/allocations', { params })
    return response.data
  })

  const isSame = (allocation1, allocation2) =>
    allocation1.personId === allocation2.personId

  return {
    ...query,
    allocate: async input => {
      const existing = query.data.findIndex(allocation =>
        isSame(allocation, input)
      )
      const result = ~existing
        ? [
            ...query.data.slice(0, existing),
            input,
            ...query.data.slice(existing + 1)
          ]
        : [...query.data, input]

      async function update () {
        await apiAxios.put('/allocations', input)
        return result
      }
      query.mutate(update(), {
        optimisticData: result
      })
    },
    disallocate: async input => {
      const result = query.data.filter(
        allocation => !_.isEqual(allocation, input)
      )
      async function update () {
        await apiAxios.delete('/allocations', {
          data: input
        })
        return result
      }
      query.mutate(update(), {
        optimisticData: result
      })
    },
    crown: async input => {
      const result = query.data.map(allocation => ({
        ...allocation,
        isLeader: allocation.personId === input.personId
      }))
      async function update () {
        await apiAxios.post('/allocations/crown', input)
        return result
      }
      query.mutate(update(), {
        optimisticData: result
      })
    }
  }
}

export const useRolesQuery = ({ userId }) => {
  const url = `/users/${userId}/roles`

  const query = useSWR(userId && url, async () => {
    const response = await apiAxios(url)
    return response.data
  })

  return {
    ...query,
    set: async input => {
      const existing = query.data.findIndex(
        role => role.placeId === input.placeId
      )
      const result =
        existing === -1
          ? [...query.data, input]
          : [
              ...query.data.slice(0, existing),
              input,
              query.data.slice(existing)
            ]
      async function update () {
        await apiAxios.put(url, input)
        return result
      }
      query.mutate(update(), {
        optimisticData: result
      })
    },
    remove: async placeId => {
      const result = query.data.filter(role => role.placeId !== placeId)
      async function update () {
        await apiAxios.delete(`${url}/${placeId}`)
        return result
      }
      query.mutate(update(), {
        optimisticData: result
      })
    }
  }
}
