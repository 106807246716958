import { useNavigate, useParams } from 'react-router-dom'
import { usePlacesQuery, useRolesQuery } from '../../../util/queries'
import useModalFlow from '../../../util/useModalFlow'
import DeleteModal from '../../molecules/DeleteModal'
import { Header } from '../../organisms/Header'
import RolesList from '../../organisms/RolesList'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function UserRolesPage () {
  const navigate = useNavigate()
  const { id: userId } = useParams()
  const { data: roles, remove } = useRolesQuery({ userId })
  const { data: places } = usePlacesQuery()
  const { modalProps, setItem } = useModalFlow(item => remove(item.placeId))

  const handleClickNew = () => {
    navigate(`/users/${userId}/roles/new`)
  }

  const handleClickDelete = role => {
    setItem(role)
  }

  const handleClickBack = () => {
    navigate('/users')
  }

  return (
    <DefaultTemplate header={<Header />}>
      <RolesList
        roles={roles}
        places={places}
        onClickNew={handleClickNew}
        onClickDelete={handleClickDelete}
        onClickBack={handleClickBack}
      />
      <DeleteModal {...modalProps} />
    </DefaultTemplate>
  )
}
