import PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'react-bootstrap'

export function DefaultTemplate ({ header, children }) {
  return (
    <>
      {header && <header>{header}</header>}
      <main>
        <Container className='mb-4'>{children}</Container>
      </main>
    </>
  )
}

DefaultTemplate.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node
}
