import PropTypes from 'prop-types'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export function AllocatorTemplate ({ header, title, calendar, lists }) {
  return (
    <>
      {header && <header>{header}</header>}
      <main>
        <Container className='mb-4'>
          {title}
          <Row>
            <Col sm={12}>{calendar}</Col>
            {lists?.map((list, index) => (
              <Col key={index} sm={12}>
                {list}
              </Col>
            ))}
          </Row>
        </Container>
      </main>
    </>
  )
}

AllocatorTemplate.propTypes = {
  header: PropTypes.node,
  lists: PropTypes.arrayOf(PropTypes.node)
}
