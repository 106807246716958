import { Outlet } from 'react-router-dom'
import './App.css'
import { LoaderPage } from './components/pages/LoaderPage'
import { SignInPage } from './components/pages/SignInPage'
import { AuthProvider } from './util/useAuth'

function App () {
  return (
    <AuthProvider>
      {({ loading, me }) =>
        loading ? <LoaderPage /> : !me ? <SignInPage /> : <Outlet />
      }
    </AuthProvider>
  )
}

export default App
