import 'bootstrap/dist/css/bootstrap.min.css'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import React from 'react'
import 'react-calendar/dist/Calendar.css'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import App from './App'
import PeoplePage, { PersonEditorPage } from './components/pages/PeoplePage'
import PlacesPage, {
  PlaceAllocationsPage,
  PlaceEditorPage
} from './components/pages/PlacesPage'
import ReportsPage, {
  ReportFormPage,
  ReportViewPage
} from './components/pages/ReportsPage'
import SettingsPage from './components/pages/SettingsPage'
import UsersPage, {
  UserEditorPage,
  UserRoleEditorPage,
  UserRolesPage
} from './components/pages/UsersPage'
import reportWebVitals from './reportWebVitals'

dayjs.locale('pt-br')

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<Navigate to='/places' />} />
          <Route path='/places'>
            <Route index element={<PlacesPage />} />
            <Route path=':id'>
              <Route index element={<PlaceEditorPage />} />
              <Route path='allocations' element={<PlaceAllocationsPage />} />
            </Route>
          </Route>
          <Route path='/people'>
            <Route index element={<PeoplePage />} />
            <Route path=':id' element={<PersonEditorPage />} />
          </Route>
          <Route path='/reports'>
            <Route index element={<ReportsPage />} />
            <Route path=':form'>
              <Route index element={<ReportFormPage />} />
              <Route path='view' element={<ReportViewPage />} />
            </Route>
          </Route>
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='/users'>
            <Route index element={<UsersPage />} />
            <Route path=':id'>
              <Route index element={<UserEditorPage />} />
              <Route path='roles'>
                <Route index element={<UserRolesPage />} />
                <Route path='new' element={<UserRoleEditorPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
