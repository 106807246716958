import libFormatCpf from '@brazilian-utils/format-cpf'
import dayjs from 'dayjs'
import { AsYouType } from 'libphonenumber-js'

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export function parseNumber (string) {
  const numbers = Number(string.replace(/[^0-9]/g, ''))
  return numbers
}

export function formatCurrency (cents) {
  return currencyFormatter.format(cents / 100)
}

export const parseCurrency = parseNumber

export function formatPhone (string) {
  const str = String(string)
  if (str.length < 4) return str
  const trimmedString = str.slice(0, 11)
  return new AsYouType('BR').input(trimmedString)
}

export const parsePhone = value =>
  parseNumber(value)
    .toString()
    .slice(0, 11)

export function formatCpf (string) {
  return libFormatCpf(string)
}

export const parseCpf = string => string.replace(/[^0-9]/g, '')

export function formatDate (date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function formatHumanDate (date) {
  return dayjs(date).format('DD/MM/YYYY')
}
