import * as XLSX from 'xlsx'

export default function exportToExcel (table, placeName, suffix) {
  const workbook = XLSX.utils.table_to_book(table, {
    cellStyles: true
  })

  const cols = [{ wch: 10 }, { wch: 7 }, { wch: 10 }, { wch: 10 }]

  workbook.Sheets.Sheet1['!cols'] = cols

  XLSX.writeFile(workbook, `Fatura de ${placeName} (${suffix}).xlsx`)
}
