import PropTypes from 'prop-types'
import { Button, Card, Col, Row } from 'react-bootstrap'
import useAuth from '../../../util/useAuth'
import Title from '../../atoms/Title'

export default function ReportsMenu ({ onChoose }) {
  const { me } = useAuth()

  const reports = me.isOwner ? ownerReports : customerReports

  return (
    <>
      <Title>Relatórios</Title>
      <Row className='g-4'>
        {reports.map(report => (
          <Col key={report.key} md={6}>
            <Card>
              <Card.Body>
                <Card.Title>{report.title}</Card.Title>
                <Card.Text>{report.text}</Card.Text>
                <Button
                  variant='success'
                  onClick={() => onChoose?.(report.key)}
                  disabled={report.disabled}
                >
                  {report.disabled ? 'Indisponível' : 'Gerar relatório'}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

ReportsMenu.propTypes = {
  onChoose: PropTypes.func
}

const ownerReports = [
  {
    key: 'payment',
    title: 'Folha de pagamento',
    text: 'Reúne as diárias de cada segurança para pagamento.'
  },
  {
    key: 'invoice',
    title: 'Fatura de estabelecimento',
    text: 'Emite a fatura mensal de um cliente para cobrança.'
  }
]

const customerReports = [
  {
    key: 'invoice',
    title: 'Fatura parcial',
    text: 'Emite a fatura parcial do seu estabelecimento.',
    disabled: true
  }
]
