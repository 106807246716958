import React from 'react'
import { Spinner as BSpinner } from 'react-bootstrap'

export default function Spinner () {
  return (
    <div className='text-center m-5'>
      <BSpinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </BSpinner>
    </div>
  )
}
