import { useNavigate } from 'react-router-dom'
import { useDeleteUserMutation, useUsersQuery } from '../../../util/queries'
import useAuth from '../../../util/useAuth'
import useModalFlow from '../../../util/useModalFlow'
import DeleteModal from '../../molecules/DeleteModal'
import { Header } from '../../organisms/Header'
import UsersList from '../../organisms/UsersList'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export default function UsersPage () {
  const navigate = useNavigate()
  const { data: users } = useUsersQuery()
  const { me } = useAuth()

  const deleteUser = useDeleteUserMutation()
  const { modalProps, setItem } = useModalFlow(item => deleteUser(item.id))

  const handleClickNew = () => {
    navigate('/users/new')
  }

  const handleClickRoles = user => {
    navigate(`/users/${user.id}/roles`)
  }

  const handleClickEdit = user => {
    navigate(`/users/${user.id}`)
  }

  const handleClickDelete = user => {
    setItem(user)
  }

  return (
    <DefaultTemplate header={<Header />}>
      <UsersList
        users={users}
        currentUser={me}
        onClickNew={handleClickNew}
        onClickRoles={handleClickRoles}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <DeleteModal {...modalProps} />
    </DefaultTemplate>
  )
}
