import { Modal } from 'react-bootstrap'
import { DEFAULT_PHOTO } from '../../../util/constants'

export default function PhotoModal ({ person, show, onClose }) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dados de {person?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <img
          src={person?.photoUrl || DEFAULT_PHOTO}
          alt={`Foto de ${person?.name}`}
          style={{ width: '100%' }}
        />
        <hr />
        <table style={{ borderCollapse: 'separate', borderSpacing: 10 }}>
          <tbody>
            <tr>
              <th>Nome de guerra:</th>
              <td>{person?.name}</td>
            </tr>
            <tr>
              <th>BP/Cia:</th>
              <td>{person?.department}</td>
            </tr>
            {person?.graduation && (
              <tr>
                <th>Graduação/Patente:</th>
                <td>{person.graduation}</td>
              </tr>
            )}
            {person?.fullName && (
              <tr>
                <th>Nome completo:</th>
                <td>{person.fullName}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}
