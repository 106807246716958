import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../../util/useAuth'
import { SignInForm } from '../../organisms/SignInForm'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

export function SignInPage () {
  const [error, setError] = useState()
  const { signIn } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const handleSignIn = async data => {
    try {
      await signIn(data)
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/')
    }
  }, [location, navigate])

  return (
    <DefaultTemplate>
      <h1>Login</h1>
      <SignInForm onSubmit={handleSignIn} error={error} />
    </DefaultTemplate>
  )
}
