import PropTypes from 'prop-types'
import { Button, Card } from 'react-bootstrap'

export default function PlaceCard ({
  place,
  onClickAllocations,
  onClickEdit,
  onClickDelete
}) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{place.name}</Card.Title>
        <Card.Text>{place.headcount} seguranças</Card.Text>
        {onClickAllocations && (
          <>
            <Button variant='primary' onClick={onClickAllocations}>
              Ver escala
            </Button>{' '}
          </>
        )}
        {onClickEdit && (
          <>
            <Button variant='secondary' onClick={onClickEdit}>
              Editar
            </Button>{' '}
          </>
        )}
        {onClickDelete && (
          <Button variant='danger' onClick={onClickDelete}>
            Excluir
          </Button>
        )}
      </Card.Body>
      {/* <Card.Footer>
        <small className='text-muted'>Não há dados de escala</small>
      </Card.Footer> */}
    </Card>
  )
}

PlaceCard.propTypes = {
  place: PropTypes.object.isRequired,
  onClickAllocations: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
}
