import { Form as FormikForm, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import { placeSchema } from '../../../util/schemas'
import FormField from '../../atoms/FormField'

export default function PlaceForm ({ initialValues, onSubmit, onCancel }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={placeSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, errors, touched }) => (
        <FormikForm>
          <Form.Group className='mb-3'>
            <Form.Label>Nome</Form.Label>
            <FormField name='name' placeholder='Nome' />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Efetivo</Form.Label>
            <FormField name='headcount' placeholder='Efetivo' />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Diária padrão</Form.Label>
            <FormField valueType='currency' name='personPrice' />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Diária líder</Form.Label>
            <FormField valueType='currency' name='leaderPrice' />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Diária revenda</Form.Label>
            <FormField valueType='currency' name='retailPrice' />
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Salvando…' : 'Salvar'}
          </Button>{' '}
          <Button variant='secondary' onClick={onCancel}>
            Cancelar
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

PlaceForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
