import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import formatDelay from '../../../util/formatDelay'
import { formatCurrency, formatHumanDate } from '../../../util/formatters'
import getDelayDiscount from '../../../util/getDelayDiscount'
import Spinner from '../../atoms/Spinner'
import styles from './styles.module.css'

export default function PaymentReportView ({
  tableRef,
  people,
  allocations,
  places
}) {
  const allocationsByPerson = useMemo(
    () => _.groupBy(allocations, 'personId'),
    [allocations]
  )

  const peopleById = useMemo(() => _.groupBy(people, 'id'), [people])

  const placesById = useMemo(() => _.groupBy(places, 'id'), [places])

  if (!people || !places || !allocations) {
    return <Spinner />
  }

  return (
    <div className={styles.wrapper}>
      {!_.keys(allocationsByPerson).length ? (
        'Não há alocações no período selecionado.'
      ) : (
        <table ref={tableRef}>
          <tbody>
            {_.map(_.keys(allocationsByPerson), personId => {
              const [person] = peopleById[personId]
              const allocations = _.chain(allocationsByPerson[personId])
                .sortBy('date')
                .filter(allocation => !allocation.isBench)
                .map(allocation => {
                  const [place] = placesById[allocation.placeId]
                  const fullPrice = allocation.isLeader
                    ? place.leaderPrice
                    : place.personPrice
                  const delayDiscount = getDelayDiscount(
                    fullPrice,
                    allocation.delay ?? 0
                  )

                  return {
                    ...allocation,
                    place,
                    isLeader: allocation.isLeader,
                    price: fullPrice - delayDiscount
                  }
                })
                .value()

              const total = _.sumBy(allocations, 'price')
              const bankingLines = person.bankingDetails
                ? _.chain(person.bankingDetails)
                    .split('\n')
                    .unshift('Dados bancários:')
                    // .thru(lines => collapseLines(lines, allocations.length))
                    .value()
                : ['(Sem dados bancários)']
              const bankingExtraLines = bankingLines.slice(
                allocations.length + 1
              )

              return (
                <React.Fragment key={personId}>
                  <tr>
                    <th colSpan={3}>
                      {person.name} ({person.department})
                    </th>
                    <th>R$ {formatCurrency(total)}</th>
                  </tr>
                  {allocations.map((allocation, index) => (
                    <tr key={index}>
                      <td className={styles.dateCell} t='s'>
                        {formatHumanDate(allocation.date)}
                      </td>
                      <td>
                        {allocation.place.name}
                        {allocation.isLeader && ' (Líder)'}
                        {allocation.delay
                          ? ` (-${formatDelay(allocation.delay)})`
                          : null}
                      </td>
                      <td className={styles.priceCell}>
                        R$ {formatCurrency(allocation.price)}
                      </td>
                      <td>{bankingLines[index]}</td>
                    </tr>
                  ))}
                  {bankingExtraLines.map((line, index) => (
                    <tr key={index}>
                      <td colSpan={3} />
                      <td>{bankingExtraLines[index]}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4} />
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

PaymentReportView.propTypes = {
  tableRef: PropTypes.object,
  people: PropTypes.arrayOf(PropTypes.object),
  places: PropTypes.arrayOf(PropTypes.object),
  allocations: PropTypes.arrayOf(PropTypes.object)
}
