import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreatePersonMutation,
  usePersonQuery,
  useUpdatePersonMutation
} from '../../../util/queries'
import Spinner from '../../atoms/Spinner'
import Title from '../../atoms/Title'
import { Header } from '../../organisms/Header'
import PersonForm from '../../organisms/PersonForm'
import { DefaultTemplate } from '../../templates/DefaultTemplate'

const personSkel = {
  photoUrl: null,
  name: '',
  department: '',
  graduation: 'Soldado',
  courses: '',
  fullName: '',
  phone: '',
  cpf: '',
  bankingDetails: ''
}

export default function PersonEditorPage () {
  const navigate = useNavigate()
  const { id } = useParams()
  const isCreating = id === 'new'

  const { data: person } = usePersonQuery(!isCreating ? id : null)
  const createPerson = useCreatePersonMutation()
  const updatePerson = useUpdatePersonMutation()

  const initialValues = isCreating ? personSkel : person

  const handleCancel = () => {
    navigate('/people')
  }

  const handleSubmit = async formData => {
    if (isCreating) {
      await createPerson(formData)
    } else {
      await updatePerson(id, formData)
    }
    handleCancel()
  }

  return (
    <DefaultTemplate header={<Header />}>
      <Title onClickBack={handleCancel}>
        {isCreating ? 'Novo segurança' : 'Editar segurança'}
      </Title>
      {!isCreating && !person ? (
        <Spinner />
      ) : (
        <PersonForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </DefaultTemplate>
  )
}
