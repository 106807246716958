import * as XLSX from 'xlsx'

export default function exportToExcel (table, suffix) {
  const workbook = XLSX.utils.table_to_book(table, {
    cellStyles: true
  })

  const cols = [{ wch: 10 }, { wch: 30 }, { wch: 10 }, { wch: 30 }]

  workbook.Sheets.Sheet1['!cols'] = cols

  XLSX.writeFile(workbook, `Folha de pagamento (${suffix}).xlsx`)
}
